import React from "react";

// External

// Internal
import Layout from "../../components/layout";
import SEO from "../../components/SEO";
import { HarmindraInternationalProductCatalogueProjectSection } from "../../containers/projects";

const HarmindraInternationalProductCatalogue = () => {
  return (
    <Layout>
      <SEO title="" description="" />
      <HarmindraInternationalProductCatalogueProjectSection />
    </Layout>
  );
};

export default HarmindraInternationalProductCatalogue;
